function App()
{
	return (
		<div className="App" style={{textAlign:"center", marginTop:"350px"}}>
			Coming Soon 
		</div>
	);
}

export default App;
